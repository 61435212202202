// ----------------------------------------------
// imports
// ----------------------------------------------
import * as $ from 'jquery';
import Menu from './components/_misc.js';
import AOS from 'aos';
import Rellax from 'rellax';
import './vendor/_transition.js';
import './vendor/_zoom.js';
import PageTransition from './components/_pageTransition.js';
import { miscFlexVid, miscZoom } from './components/_miscellaneous.js';
import OpacityScroll from './components/_opacityScroll.js';

// ----------------------------------------------
// inits
// ----------------------------------------------
$(() => {

  // inits
  AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: true
  });
  PageTransition.init();
  OpacityScroll.init();

  if ($('#markdown').length) {
    miscFlexVid();
    miscZoom();
  }
});

const rellax = new Rellax('.rellax');

const element = document.querySelector('.articles');
const button  = document.querySelector('.posts__more');
let page      = parseInt(element.getAttribute('data-page'));

const fetchArticles = async () => {
  let url = `${window.location.href.split('#')[0]}.json/page:${page}`;
  try {
    const response       = await fetch(url);
    const { html, more } = await response.json();
    button.hidden        = !more;
    element.insertAdjacentHTML('beforeend', html);
    page++;
  } catch (error) {
    console.log('Fetch error: ', error);
  }
}

button.addEventListener('click', fetchArticles);
