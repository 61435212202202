// ----------------------------------------------
// imports
// ----------------------------------------------
import $ from 'jquery';

// ----------------------------------------------
// flex vid
// ----------------------------------------------
const miscFlexVid = () => {
  const iframeArr = $('.article__content iframe');

  iframeArr.each((idx, iframe) => {
    $(iframe).wrap('<div class="flex-vid"></div>');
  });
};

// ----------------------------------------------
// zoom
// ----------------------------------------------
const miscZoom = () => {
  const imgArr = $('.article__content img');

  imgArr.each((idx, img) => {
    if (img.src.indexOf('full') === -1) {
      $(img).attr('data-action', 'zoom');
    }
  });
};

// ----------------------------------------------
// exports
// ----------------------------------------------
export { miscFlexVid, miscZoom };
